:root {
  --light-color: #EDF8F7;
  --dark-color: #39474E;
  --green-color: #C1D21E;
  --blue-color: #4B8E8D;
  --btn-back-color: #39474ec0;
  --overlay-color: #39474ec2;
  --footer-color: #223139;
}

::selection {
  color: var(--light-color);
  background-color: var(--green-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: mainFont;
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: mainFontBold;
  src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: buttonFont;
  src: url('./fonts/Sofia-Pro-Regular-Az.otf');
}

body {
  font-family: mainFont, sans-serif;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

main {
  min-height: 100vh;
  flex: 1;
}

.main-section {
  background-color: var(--dark-color);
  height: 100vh;
}

.main-container {
  width: calc(100vw - 40px);
  height: calc(100% - 130px);
  margin: 0px auto;
  padding-bottom: 50px;
}

.main-btn {
  text-decoration: none;
  display: block;
  width: fit-content;
  padding: 18px 20px;
  color: var(--light-color);
  background-color: var(--btn-back-color);
  border: 2px solid var(--green-color);
  font-family: buttonFont, sans-serif;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.19s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.main-btn:hover {
  color: var(--green-color);
  transform: scale(1.025);
  -webkit-transform: scale(1.025);
  -moz-transform: scale(1.025);
  -ms-transform: scale(1.025);
  -o-transform: scale(1.025);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--dark-color);
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background-color: var(--light-color);
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;
}

@supports(scrollbar-color: var(--light-color) var(--blue-color)) {
  * {
    scrollbar-color: var(--light-color) var(--blue-color);
    scrollbar-width: thin;
  }
}

.buy-container {
  width: 88.7%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 115px);
  max-height: -webkit-fill-available;
}

/** Start Media **/
@media (max-width: 920px) {

  .main-section {
    min-height: 100vh;
    height: auto;
  }

  .main-container {
    min-height: calc(100% - 130px);
    height: auto;
  }

  .main-container {
    width: calc(100% - 20px);
    padding-bottom: 25px;
  }
}

@media (min-width: 577px) and (max-width: 820px) {
  body {
    font-size: 4vmin;
  }

  .main-section,
  .main-container {
    min-height: 100vh;
    height: auto;
  }
}

/** End Media **/